import axios from 'axios'
import type {AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig} from 'axios'
import {ElLoading} from 'element-plus'

interface Interceptors {
  requestInterceptors?: (config: InternalAxiosRequestConfig) => InternalAxiosRequestConfig
  requestInterceptorsCatch?: (error: any) => any
  responseInterceptors?: (response: AxiosResponse) => AxiosResponse
  responseInterceptorsCatch?: (error: any) => any
}

interface RequestConfig extends AxiosRequestConfig {
  interceptors?: Interceptors
}

class Instance {
  public instance: AxiosInstance

  loadingInstance: any
  constructor(config: RequestConfig) {
    this.instance = axios.create(config)
    //局部拦截器
    this.instance.interceptors.request.use(
      config.interceptors?.requestInterceptors,
      config.interceptors?.requestInterceptorsCatch
    )
    this.instance.interceptors.response.use(
      config.interceptors?.responseInterceptors,
      config.interceptors?.responseInterceptorsCatch
    )
    //全局拦截器
    this.instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      this.loadingInstance = ElLoading.service({
        background: 'rgba(0,0,0,0.0)',
        lock: true,
        fullscreen: true
      })
      return config
    }, (error) => {
      this.loadingInstance.close()
      return Promise.reject(error)
    })
    this.instance.interceptors.response.use((response: AxiosResponse) => {
      this.loadingInstance.close()
      return response
    }, (error) => {
      this.loadingInstance.close()
      return Promise.reject(error)
    })
  }

  instanceRequest(config: RequestConfig) {
    //单个请求拦截器
    if (config?.interceptors) {
      this.instance.interceptors.request.use(
        config.interceptors?.requestInterceptors,
        config.interceptors?.requestInterceptorsCatch
      )
      this.instance.interceptors.response.use(
        config.interceptors?.responseInterceptors,
        config.interceptors?.responseInterceptorsCatch
      )
    }

    return new Promise((resolve, reject) => {
      this.instance(config).then((response: AxiosResponse) => {
        return resolve(response)
      }).catch((error) => {
        return reject(error)
      })
    })
  }
}

export default Instance