import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/el-loading.css';
// @ts-ignore
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
// @ts-ignore
import print from 'vue3-print-nb'

const app = createApp(App)

app.use(print)
app.use(store)
store.dispatch('registeredRoute')
app.use(router)
app.use(ElementPlus, {
  locale: zhCn,
})
app.mount('#app')

const debounce = (fn: any, delay: number) => {
  let timer: any = null;
  return function () {
    // @ts-ignore
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback:any) {
    callback = debounce(callback, 16);
    super(callback);
  }
}
