let BASE_URL: string = ''
let TIME_OUT: number = 0

if (process.env.NODE_ENV === 'development') {
  BASE_URL = 'http://127.0.0.1:56000'
  TIME_OUT = 10000
} else if (process.env.NODE_ENV === 'production') {
  BASE_URL = 'https://apixyc.codetl.com'
  TIME_OUT = 10000
}
// https://api.codettnt.com
export { BASE_URL, TIME_OUT }