import Instance from '@/network/instance'
import {BASE_URL, TIME_OUT} from '@/network/config'
import store from '@/store/index'
import router from "@/router"

const request = new Instance({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptors: (config) => {
      return config
    },
    responseInterceptors: (response) => {
      return response
    }
  }
})

const requestVerify = new Instance({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptors: (config) => {
      const token: string | null = sessionStorage.getItem('o_m_s')
      if (token) {
        config.headers.Authorization = `${token}`
        config.headers.userid = store.state.info?.user_id.toString()
      }
      return config
    },
    responseInterceptors: (response) => {
      return response
    },
    responseInterceptorsCatch: (error) => {

      if (error.response.status === 401) {
        const exclude: string = "Goods,Order,Type,User,Login,AddOrder,OutboundStatistics,Home"
        sessionStorage.removeItem('o_m_s')
        store.commit('changeExcludeOut', exclude)
        store.commit('clearInfo')
        router.replace('/')
        return Promise.reject(error)
      } else {
        return Promise.reject(error)
      }
    }
  }
})

export {request, requestVerify}