import {createRouter, createWebHistory, Router, RouteRecordRaw} from 'vue-router'
import {requestVerify} from '@/network/request'
import store from '@/store/index'
import {ref} from "vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      navBar: false,
      token: false
    },
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/:pathMatch(.*)',
    name: 'notFound',
    component: () => import(/*webpackChunkName:"notFound-chunk"*/'@/views/NotFound.vue')
  }
]

const router: Router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.token === true) {
    if (sessionStorage.getItem('o_m_s')) {
      requestVerify.instanceRequest({
        url: '/verify/token',
        method: 'get'
      }).then((res) => {
        next()
      }).catch((err) => {
        const exclude: string = "Goods,Order,Type,User,Login,AddOrder,OutboundStatistics,Home"
        sessionStorage.removeItem('o_m_s')
        store.commit('changeExcludeOut', exclude)
        store.commit('clearInfo')
        next('/')
      })
    } else {
      next('/')
    }
  } else {
    next()
  }
})

export default router
