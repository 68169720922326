import {createStore} from 'vuex'
import _router from '@/router/index'

// @ts-ignore
import createPersistedState from 'vuex-persistedstate'//解决vuex刷新后数据丢失的问题

type RootState = {
  exclude: string,
  info: {
    /**
     * 数据库自增id
     */
    id: number;
    /**
     * 姓名
     */
    name: string;
    /**
     * 手机号
     */
    phone: string;
    /**
     * PC端前端路由列表
     */
    router_list: {
      component: string,
      meta: {
        navBar: boolean,
        token: boolean
      },
      name: string,
      path: string,
      router_id: number,
      tab_name: string
    };
    /**
     * 用户id
     */
    user_id: number;
    /**
     * 账号名
     */
    user_name: string;
    [property: string]: any;
  } | null
}

export default createStore<RootState>({
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })],
  state() {
    return {
      info: null,
      exclude: "Login,AddOrder"
    }
  },
  getters: {},
  mutations: {
    setInfo(state, value) {
      state.info = value
    },
    clearInfo(state) {
      state.info = null
    },
    changeExcludeOut(state, value) {
      state.exclude = value
    },
    changeExcludeLogin(state) {
      state.exclude = "Login,AddOrder"
    }
  },
  actions: {
    registeredRoute(context: any) {
      if (context.state.info !== null) {
        for (const item of context.state.info.router_list) {
          const route = {
            path: item.path,
            name: item.name,
            meta: {
              navBar: item.meta.navBar,
              token: item.meta.token
            },
            component: () => import(`@/views/${item.component}`)
          }
          _router.addRoute(route)
        }
      }

    }
  },
  modules: {}
})
